import React from "react";
import { Link } from "gatsby";
import {
  IoIosLock,
  IoIosFiling,
  IoIosFlash,
  IoIosContacts,
  IoIosCloud,
  IoIosHappy,
} from "react-icons/io";
import { useModal } from "../components/useModal";

import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Image from "../components/image";
import Seo from "../components/seo";
import {
  CallToAction,
  Modal,
  CustomerQuote,
  ProductPricing
} from "../components/site";

import styled from "styled-components";
import PayrollAwardBadge from "../images/home/PayrollAwardBadge_Black.svg";

const AwardBadge = styled.img`
  position: relative;
  width: 250px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 180px;
  }
`;

const IMSPayroll = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  return (
    <Layout>
      <Seo
        title="Switch from IMS Payroll to PayHero Online Payroll"
        description="It’s a great time to kick your old IMS Payroll system to the kerb and move to PayHero’s state of the art online payroll software."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={70} maxWidth={1200} noPaddingBottom>
          <Box size={100} stackGap={40} centerOnMobile>
            <Box stackGap={10}>
              <h1 className="-textCenter -fontLarge">
                Switch from IMS Payroll{" "}
                <span className="-fontPrimary">to PayHero</span>
              </h1>
              <h4 className="-textCenter -fontNormal">
                PayHero is an IMS Payroll alternative built to eliminate payroll
                compliance headaches for Kiwi businesses. Converting is free &
                easy and we’ll help you every step of the way.
              </h4>
            </Box>
            <Box stackGap={20}>
              <FlexButtons justify="center">
                <Button className="primary -lg gtm-cta" to="/signup">
                  Start Free Trial
                </Button>
                <Button
                  className="secondary -lg gtm-cta"
                  onClick={toggleModal}
                  atag
                >
                  Book a Sales Call
                </Button>
              </FlexButtons>
              <p className="-textCenter" css={{ color: "#999" }}>
                14 Days Free · First Pay Walkthrough · No Credit Card Required
              </p>
            </Box>
          </Box>
          <Box>
            <Image
              filename="PayHeroHero_Cropped.png"
              alt="PayHero"
              maxWidth={1000}
              centerImage
              addShadow
            />
          </Box>
        </Wrapper>
      </Container>
      <Container bg="lightGrey" className="-textCenter">
        <Wrapper css={{ paddingTop: "60px" }}>
          <AwardBadge
            className="-center"
            src={PayrollAwardBadge}
            alt=""
          />
          <h2 css={{ marginTop: "60px" }}>Why is PayHero New Zealand’s best payroll system?</h2>
          <Box stackGap={50}>
            <Row stackGap={50}>
              <Box size={33}>
                <h3>
                  <IoIosLock />
                  <br />
                  We take compliance seriously
                </h3>
                <p>
                  PayHero was built to follow the 2017 Holidays Act guidance published by MBIE.
                  We store annual leave in weeks and review recent work patterns when leave is
                  taken to determine the right entitlements.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosCloud />
                  <br />
                  Changes are made automatically
                </h3>
                <p>
                  Any changes to payroll and labour laws are automatically applied to PayHero’s
                  {" "}<Link to="/payroll">cloud-based system</Link>. No downloads or time
                  consuming updates.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosFlash />
                  <br />
                  Your accounts are sorted
                </h3>
                <p>
                  Seamlessly{" "}
                  <Link to="/xero">send your payroll transactions to Xero</Link>{" "}
                  and apportion wage costs to different account codes or
                  tracking categories.
                </p>
              </Box>
            </Row>
            <Row stackGap={50}>
              <Box size={33}>
                <h3>
                  <IoIosFiling />
                  <br />
                  No need for a filing cabinet
                </h3>
                <p>
                  Securely store employment contracts, IRD forms, work permits, licences and 
                  other important documents on each employee’s profile.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosContacts />
                  <br />
                  Employees also benefit
                </h3>
                <p>
                  Give{" "}
                  <Link to="/time-portal">employees access</Link>{" "}to submit timesheets, view 
                  balances & pay history, request leave, submit expense claims and update their 
                  details.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosHappy />
                  <br />
                  Free and knowledgeable support
                </h3>
                <p>
                  Our friendly Wellington-based support team is available by phone or email to help 
                  whenever you need it.
                </p>
              </Box>
            </Row>
          </Box>
          <CustomerQuote
            quote="We’re usually reluctant to endorse payroll software, simply because the cost of getting it wrong is huge. PayHero gets the detail right, at the same time as being exceptionally easy to use. In short, it’s second to none."
            name="Hamish Mexted"
            company="Convex Accounting"
            pic="Hamish_Circle.png"
            large
            center
          />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper noPaddingBottom>
          <Box stackGap={60}>
            <div className="-textCenter">
              <h2>PayHero Pricing</h2>
              <h4>No Hidden Costs · Easy Set Up · 14 Day Free Trial</h4>
            </div>
            <ProductPricing
              selectedProduct="payhero"
              ctaLink="/try-paye#signup"
              hideAddOns
            />
            <p className="-textCenter" css={{ color: "#999" }}>
              Prices are in NZD and exclude GST.
            </p>
          </Box>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper noPaddingBottom>
          <Row stackGap={80} alignCenter mobileReverse>
            <Box size={50}>
              <Image
                alt="PayHero Team | ABV 2021 Photo"
                filename="FlexiTime_ABV2021.jpg"
                addShadow
                rounded
              />
            </Box>
            <Box stackGap={10} size={50}>
              <h5 className="-fontPrimary">WHO ARE WE?</h5>
              <h2>Meet the team</h2>
              <p>
                PayHero is brought to you by the team at{" "}
                <Link to="https://www.flexitime.works">FlexiTime</Link>
                . We’ve been making it easy for Kiwis to work and get paid since
                2008. Back then our founders Rob, Sam and Jill got together and
                built FlexiTime Payroll, one of the first cloud based payroll
                systems in New Zealand.
              </p>
              <p>
                Since then, we’ve built up a reputation as the go-to provider
                for businesses with complex payroll requirements - especially
                those with casual, part-time and remote employees.
              </p>
              <p>
                Fast forward ten years and it was time for a refresh. In 2019
                our fast growing team in Wellington launched a new and improved
                payroll system - PayHero. It’s the culmination of everything
                we’ve learned over a decade of helping thousands of customers
                get their payroll right.
              </p>
              <p>
                Though we’re experts when it comes to companies with tricky
                payroll requirements, businesses from every industry choose
                PayHero for our time tracking tools, employee self-service,
                advanced reporting, smart integrations, expert support and easy
                payment options.
              </p>
            </Box>
          </Row>
          <hr />
        </Wrapper>
      </Container>
      <CallToAction
        title={[
          "Swap from IMS Payroll and pay employees right with ",
          <br className="hideOnMobile" />,
          <span className="-fontPrimary">powerful online payroll</span>,
        ]}
        showSalesButton
        hideDesc
      />
      <Container bg="lightGrey" className="-textCenter">
        <Wrapper stackGap={50}>
          <h4>
            <span className="-fontBold">
              Ready to go but not sure how to switch payroll systems?
            </span>{" "}
            Download our{" "}
            <Link to="/guides/switching-payroll-systems">step-by-step guide here</Link>.
          </h4>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper stackGap={20}>
          <p className="-textCenter" css={{ marginBottom: "0" }}>
            * As voted by members for the Institute of Certified New Zealand
            Bookkeepers Annual Excellence Awards.
          </p>
        </Wrapper>
      </Container>
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default IMSPayroll;
